import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import ReactDataGrid from "react-data-grid";
import { Progress } from "reactstrap";
import { statistics } from "../../../api/reports";
import { features, isFeatureEnabled } from "../../../config";
import { dynamicSortAlphaNumericMultiple } from "../../../helpers/sort";
import { chunk, measureText } from "../../../helpers/utils";
import useWindowSize from "../../../hooks/useWindowSize";
import Loader from "../../common/Loader";

const StatisticsReportDisplay = ({ filterData, reportComplete }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [columns, setColumns] = useState([]);
	const [rowCount, setRowCount] = useState(0);
	const size = useWindowSize();
	const [minHeight, setMinHeight] = useState(500);
	const [dateString, setDateString] = useState("");
	const [progress, setProgress] = useState(0);
	const [progressLength, setProgressLength] = useState(0);

	const updateProgress = useCallback(() => {
		setProgress((p) => p + 1);
	}, []);

	useEffect(() => {
		size.then((r) => setMinHeight(r.height - 225));

		var dashboardContentDiv = document.getElementById("dashboard-container-div");

		if (dashboardContentDiv) {
			var contentDiv = document.getElementById("statistics-report-div");

			if (contentDiv) {
				contentDiv.style.marginRight = `-${dashboardContentDiv.offsetLeft}px`;
			}
		}
	}, [size]);

	useEffect(() => {
		const LoadData = async () => {
			const { locationIds, startTime, endTime, stats, arrangeBy, period } = filterData;
			setDateString("");

			if (locationIds.length === 0) {
				setData(null);
				setRowCount(0);
				setLoading(false);
				reportComplete(undefined);
			} else {
				let resultsData = null;

				const chunks = chunk(locationIds, 50);
				setProgress(0);
				setProgressLength(chunks.length);

				for (const i in chunks) {
					// !!! Have to call like this to get only one API happening at a time
					var result = await statistics(chunks[i].join(","), startTime, endTime, stats.join(","), period, isFeatureEnabled(features.Seconds));
					updateProgress();

					let temp = "";
					if (result.fromDateTime) {
						temp = `${moment(result.fromDateTime).format("DD/MM/YYYY HH:mm")} - `;
					}
					if (result.toDateTime) {
						temp = `${temp}${moment(result.toDateTime).format("DD/MM/YYYY HH:mm")}`;
					}
					setDateString(temp);

					//First set?
					if (!resultsData) {
						resultsData = result;
					} else {
						//Append new rows
						resultsData.rows.push(...result.rows);
						resultsData.rowsCount = resultsData.rowsCount + result.rowsCount;
					}

					//Apply cell formatters
					resultsData.columns.forEach((column) => {
						column.formatter = NormalFormatter;
					});

					//Sort Data
					if (arrangeBy === "Group") {
						resultsData.rows.sort(dynamicSortAlphaNumericMultiple("Building", "Zone", "GroupIndex", "Group", "Param"));
					} else {
						resultsData.rows.sort((a, b) => {
							if (a.Param === b.Param) {
								if (a.Building === b.Building) {
									if (a.Zone === b.Zone) {
										return a.Group > b.Group ? 1 : -1;
									}
									return a.Zone > b.Zone ? 1 : -1;
								}
								return a.Building > b.Building ? 1 : -1;
							}
							return a.Param > b.Param ? 1 : -1;
						});
					}

					// Resize frozen columns
					for (var col = 0; col < resultsData.columns.length; ++col) {
						if (resultsData.columns[col].frozen) {
							let width = (measureText(resultsData.columns[col].name, 13, null)?.width || 0) + 16;
							try {
								for (let i = 0; i < resultsData.rows.length; ++i) {
									const thisWidth = measureText(resultsData.rows[i][resultsData.columns[col].key], 12, null)?.width + 16;
									if (thisWidth > width) {
										width = thisWidth;
									}
								}
							} catch {}
							resultsData.columns[col].width = width;
						}
					}

					//Show it
					setColumns(resultsData.columns.concat());
					setData(resultsData);
					setRowCount(resultsData.rowsCount); //This causes the redraw, if not done then only first set of data is shown
					setLoading(false);
				}
				reportComplete(resultsData);
			}
		};

		LoadData();
	}, [filterData, reportComplete, updateProgress]);

	const NormalFormatter = ({ value }) => (
		<div title={value} className="rdg_innerCell">
			{value}
		</div>
	);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<>
					{filterData.stats && filterData.stats.includes("start-end-time-of-data") && <div>{dateString}</div>}
					<div className="rdg-no-gutters rounded-grid">{data && rowCount > 0 && <ReactDataGrid columns={columns} minColumnWidth={40} resizable={true} rowGetter={(i) => data.rows[i]} rowsCount={rowCount} minHeight={minHeight} enableRowSelect={null} />}</div>
				</>
			)}
			{progressLength > 0 && progress !== progressLength && <Progress animated value={progress} max={progressLength} />}
		</>
	);
};

export default StatisticsReportDisplay;
