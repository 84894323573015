import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Card, CardBody, CardFooter, CardHeader, Col } from "reactstrap";
import { formatDateTime } from "../../helpers/dateTimeHelper";
import local from "../../localization/strings";
import Pin from "../common/Pin";
import BatteryIcon from "./BatteryIcon";
import { WidgetTitleLevel1, WidgetTitleLevel2 } from "../common/WidgetTitles";

const dividerBorder = "1px solid rgba(255, 255, 255, 0.15)";

const Meter = ({ reading, showTransmitterDetails }) => {
	const cardBgStyle = () => {
		if (typeof reading.logging === "boolean" && !reading.logging) {
			return "widgetBlueNoAlarm";
		}
		if (reading?.alarmType === "MainLo" || reading?.alarmType === "MainHi") {
			return reading?.clearedBy ? "widgetInAlarmCleared" : "widgetInAlarm";
		}
		return "widgetBlueNoAlarm";
	};

	const ReadingValue = () => {
		if (typeof reading.logging === "boolean" && !reading.logging) {
			return <span className="meter-no-data font-weight-normal">{local.TF_OFF}</span>;
		}
		if (reading.realValue === null) {
			return <span className="meter-no-data font-weight-normal">{local.TS_No_Data}</span>;
		} else {
			return (
				<Fragment>
					<span className="display-3 font-weight-normal">{reading.realValue.toFixed(reading.decimalPlaces)}</span>
					<span className="font-weight-normal">{reading.units}</span>
				</Fragment>
			);
		}
	};

	return (
		<Card className={`h-100 ${cardBgStyle()} m-0`} style={{ minWidth: "200px" }}>
			<CardHeader className="bg-transparent pt-2 px-1 pb-0">
				<h5 className="col-11 bg-transparent">
					<WidgetTitleLevel1 reading={reading} />
				</h5>
				<Col className="col-1" style={{ position: "absolute", right: 15, top: 15, zIndex: 800 }}>
					<Pin color="light" types={[{ name: `${local.TF_Pin_Meter}`, type: "DashMeterBlock", data: reading.locationId }, { name: `${local.TF_Pin_Meter_Blue}`, type: "DashMeterBlockBlue", data: reading.locationId }]} size="sm" className="menu-pin-white" menuClass="menu-border-blue" tag="div" />
				</Col>
			</CardHeader>
			<CardBody className="fs--1 pt-0">
				<ReadingValue />
				<div className="font-weight-normal">
					{reading.parameterName} {reading.outOfDateReading && <FontAwesomeIcon className="danger-icon fs-1" size="lg" icon={faExclamationCircle} title={`OUT OF DATE: ${formatDateTime(reading.dateTime)}`} />}
				</div>
				{showTransmitterDetails && (
					<Fragment>
						<hr className="mx-n3" style={{ borderTop: dividerBorder }} />
						<div className="text-center">
							{local.TS_Transmitter}: {reading.transmitterSerialNumber}
						</div>
						<div className="text-center">
							<BatteryIcon reading={reading.transmitterBatteryLevel} />
						</div>
					</Fragment>
				)}
			</CardBody>
			<CardFooter className="text-right bg-transparent" style={{ borderTop: dividerBorder }}>
				<WidgetTitleLevel2 reading={reading} />
			</CardFooter>
		</Card>
	);
};

Meter.propTypes = {
	reading: PropTypes.object.isRequired,
};

export default Meter;
