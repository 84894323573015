import moment from "moment";
// import "rc-slider/assets/index.css";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, Col, Label, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import { siteAnnualSmsSegmentLimitSet } from "../../api/site";
import { apiSmsPackageDelete, apiSmsPackageSave, apiSmsPackagesGet } from "../../api/smsPackages";
import local from "../../localization/strings";
import Loader from "../common/Loader";
import confirm from "reactstrap-confirm";

const SiteSms = ({ site }) => {
	const defaultSmsPackage = {
		id: undefined,
		segments: undefined,
		used: undefined,
		expiryDate: moment()
			.add(1, "year")
			.format("YYYY-MM-DD"),
		isFreePackage: false,
		notes: undefined,
	};

	const [listSize, setListSize] = useState(10);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [smsPackages, setSmsPackages] = useState(undefined);
	const [showLoading, setShowLoading] = useState(true);
	const [smsPackage, setSmsPackage] = useState(defaultSmsPackage);

	useEffect(() => {
		const LoadSmsPackages = async () => {
			setSmsPackages(await apiSmsPackagesGet(site.id));
			setShowLoading(false);
		};
		LoadSmsPackages();
	}, [site]);

	async function saveAnnualSmsSegmentLimit(_e, values) {
		await siteAnnualSmsSegmentLimitSet(site.id, values.annualSmsSegmentLimit);
		toast.success(local.TS_Update_Successful);
	}

	async function saveSmsPackage(_e, values) {
		setShowLoading(true);

		try {
			await apiSmsPackageSave(site.id, smsPackage.id, values.segmentsAdded, values.expiryDate, values.isFreePackage, values.notes);
			toast.success(local.TS_Update_Successful);
		} catch (error) {
			toast.error(local.TF_SmsPackageSaveError);
		}

		// Reload packages
		setSmsPackages(await apiSmsPackagesGet(site.id));
		setModalIsOpen(false);
		setShowLoading(false);
	}

	async function deleteSmsPackage(id) {
		var result = await confirm({
			title: local.TF_SmsDeletePackage,
			message: local.TF_Delete_SmsPackage_Are_You_Sure,
			confirmColor: "danger",
			confirmText: local.TF_SmsDeletePackage,
		});

		if (result) {
			setShowLoading(true);
			try {
				await apiSmsPackageDelete(site.id, id);
				toast.success(local.TS_Update_Successful);
			} catch (error) {
				toast.error(local.TF_SmsPackageDeleteError);
			}

			// Reload packages
			setSmsPackages(await apiSmsPackagesGet(site.id));
			setModalIsOpen(false);
			setShowLoading(false);
		}
	}

	const getMinimumSegments = () => {
		return smsPackage.id && smsPackage.used > 0 ? smsPackage.used : 1;
	};

	const getMinimumExpiryDate = () => {
		return smsPackage.id ? moment(smsPackage.expiryDate) : moment().add(1, "day");
	};

	return (
		<>
			{showLoading ? (
				<Loader />
			) : (
				<>
					<Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)} centered backdrop="static">
						<ModalHeader>
							<span className="float-left">{smsPackage.id ? local.TF_SmsEditPackage : local.TF_SmsAddPackage}</span>
							<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => setModalIsOpen(false)}>
								X
							</span>
						</ModalHeader>
						<ModalBody>
							<AvForm model={smsPackage} onValidSubmit={async (e, values) => await saveSmsPackage(e, values)}>
								<AvField name="segmentsAdded" type="number" label={smsPackage.id && smsPackage.used > 0 ? `${local.TF_SmsSegments} (${smsPackage.used} ${local.TF_Used})` : local.TF_SmsSegments} validate={{ required: { value: true, errorMessage: local.TS_Required }, min: { value: getMinimumSegments(), errorMessage: `${local.TF_ValidateGreaterEqual} ${getMinimumSegments()}` }, step: { value: 1, errorMessage: local.TF_ValidatePositiveInteger } }} />
								<AvField
									name="expiryDate"
									label={local.TF_SmsExpiryDate}
									type="date"
									min={getMinimumExpiryDate().format("YYYY-MM-DD")}
									validate={{
										required: { value: true, errorMessage: local.TS_Required },
										dateRange: {
											format: "YYYY-MM-DD",
											start: { value: getMinimumExpiryDate().format("YYYY-MM-DD") },
											end: {
												value: getMinimumExpiryDate()
													.add(100, "year")
													.format("YYYY-MM-DD"),
											},
											errorMessage: `${local.TF_ValidateSmsExpiry} ${getMinimumExpiryDate().format("DD MMM YYYY")}`,
										},
									}}
								/>
								<Label for="isFreePackage">{local.TF_SmsPackageType}</Label>
								<AvRadioGroup name="isFreePackage">
									<AvRadio label="Paid" value={false} />
									<AvRadio label="Free" value={true} />
								</AvRadioGroup>
								<AvField name="notes" type="textarea" rows="3" maxLength="1000" label={local.TF_Notes} />
								<Row>
									<Col>
										<Button disabled={showLoading} color="primary" className="mt-2 float-left">
											{local.TS_Save}
										</Button>
										<Button color="secondary" className="mt-2 ml-2 float-left" onClick={() => setModalIsOpen(false)}>
											{local.TS_Cancel}
										</Button>
										{smsPackage.id && smsPackage.used === 0 && (
											<Button disabled={showLoading} color="link" className="mt-2 float-right" onClick={() => deleteSmsPackage(smsPackage.id)}>
												{local.TS_Delete}
											</Button>
										)}
									</Col>
								</Row>
							</AvForm>
						</ModalBody>
					</Modal>
					<Card className="p-3">
						<h4>{local.TF_SmsAnnualFreeSegmentLimit}</h4>
						<AvForm onValidSubmit={async (e, values) => await saveAnnualSmsSegmentLimit(e, values)}>
							<span className="small">{local.TF_SmsAnnualFreeSegmentExplanation}</span>
							<AvField name="annualSmsSegmentLimit" type="number" validate={{ required: { value: true, errorMessage: local.TS_Required }, min: { value: 1, errorMessage: local.TF_ValidatePositiveInteger }, step: { value: 1, errorMessage: local.TF_ValidatePositiveInteger } }} value={site.annualSmsSegmentLimit} />
							<Row>
								<Col>
									<Button color="primary" className="mt-1">
										{local.TS_Save_Changes}
									</Button>
								</Col>
							</Row>
						</AvForm>
					</Card>
					<Card className="p-3 mt-3">
						<div className="d-flex justify-content-between w-full">
							<div>
								<h4>{local.TF_SmsPackageHistory}</h4>
							</div>
							<div>
								<Button
									type="button"
									color="primary"
									onClick={() => {
										setSmsPackage(defaultSmsPackage);
										setModalIsOpen(true);
									}}
								>
									{local.TF_SmsAddPackage}
								</Button>
							</div>
						</div>

						<Table className="table-hover table-sm mb-0 mt-2 fs--1 rounded-soft table-striped">
							<thead className="thead-light">
								<tr>
									<th>{local.TF_SmsSegmentsAdded}</th>
									<th>{local.TF_SmsSegmentsRemaining}</th>
									<th className="text-nowrap">{local.TF_SmsExpiryDate}</th>
									<th>{local.TF_SmsPackageType}</th>
									<th className="d-none d-md-table-cell text-nowrap">{local.TF_SmsDateAdded}</th>
									<th className="d-none d-md-table-cell text-nowrap">{local.TF_SmsAddedBy}</th>
									<th className="d-none d-md-table-cell">{local.TF_Notes}</th>
								</tr>
							</thead>
							<tbody>
								{smsPackages &&
									smsPackages.slice(0, listSize).map((item, key) => (
										<tr
											key={key}
											className={item.isActive ? "cursor-pointer" : "table-danger hover"}
											onClick={() => {
												if (item.isActive) {
													setSmsPackage({
														...item,
														used: item.segmentsAdded - item.segmentsRemaining,
														expiryDate: moment(item.expiryDate).format("YYYY-MM-DD"),
													});
													setModalIsOpen(true);
												}
											}}
										>
											<td>{item.segmentsAdded}</td>
											<td>{item.segmentsRemaining}</td>
											<td className="text-nowrap">{moment(item.expiryDate).format("DD MMM YYYY")}</td>
											<td>{item.isFreePackage ? local.TF_SmsPackageTypeFree : local.TF_SmsPackageTypePaid}</td>
											<td className="d-none d-md-table-cell text-nowrap">{moment(item.dateAdded).format("DD MMM YYYY")}</td>
											<td className="d-none d-md-table-cell">{item.addedByName}</td>
											<td className="d-none d-md-table-cell">{item.notes}</td>
										</tr>
									))}
							</tbody>
						</Table>
						{smsPackages.length === 0 && <div className="p-2">{local.TF_SmsPackageNone}</div>}
						{smsPackages && listSize < smsPackages.length && (
							<Button className="btn-sm mt-2" onClick={() => setListSize(listSize + 10)} color="secondary">
								{local.TF_SmsLoadMore}
							</Button>
						)}
					</Card>
				</>
			)}
		</>
	);
};

export default SiteSms;
