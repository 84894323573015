import axios from "axios";
import { FixDate } from "../helpers/dateTimeHelper";

export const apiSmsPackageSave = async (siteId, Id, segmentsAdded, expiryDate, isFreePackage, notes) => {
	await axios({
		method: "post",
		url: "v2/SmsPackage",
		data: {
			siteId,
			Id,
			segmentsAdded: segmentsAdded,
			expiryDate: FixDate(expiryDate),
			isFreePackage: isFreePackage,
			notes: notes,
		},
	});
};
export const apiSmsPackageDelete = async (siteId, Id) => {
	try {
		await axios({
			method: "delete",
			url: "v2/SmsPackage",
			data: {
				siteId,
				Id,
			},
		});
	} catch (err) {
		console.error(err);
	}
};
export const apiSmsPackagesGet = async (siteId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/SmsPackages",
			params: {
				SiteId: siteId,
			},
		});
		return result.data;
	} catch (err) {
		console.error(err);
	}
};
