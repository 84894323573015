import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { logEvent } from "../../../../helpers/ga";
import { uniqueTransmitterSerial } from "../../../../helpers/utils";
import withRedirect from "../../../../hoc/withRedirect";
import local from "../../../../localization/strings";
import Loader from "../../../common/Loader";

import CanvasJSReact from "../../../../lib/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const BatteryLevelDoughnut = ({ latestReadings, viewBatteries }) => {
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [lowBatteries, setLowBatteries] = useState([]);

	useEffect(() => {
		let isSubscribed = true;
		setLoading(true);
		const LoadData = () => {
			var transmitterArray = [];
			var lowBatteryArray = [];

			if (latestReadings) {
				const transmittersX = [];
				latestReadings.forEach((lr) => {
					if (lr.transmitterSerialNumber && !transmittersX.find((x) => x.transmitterSerialNumber === lr.transmitterSerialNumber)) {
						transmittersX.push({ transmitterSerialNumber: lr.transmitterSerialNumber, transmitterBatteryLevel: lr.transmitterBatteryLevel });
					}
				});
				transmittersX.forEach((x) => {
					if (x.transmitterBatteryLevel > 100 || x.transmitterBatteryLevel === null) {
						x.transmitterBatteryLevel = -1;
					}
				});

				var batteryVeryLow = transmittersX.filter((x) => x.transmitterBatteryLevel >= 0 && x.transmitterBatteryLevel <= 10).length;
				var batteryLow = transmittersX.filter((x) => x.transmitterBatteryLevel >= 11 && x.transmitterBatteryLevel <= 20).length;
				var batteryNormal = transmittersX.filter((x) => x.transmitterBatteryLevel >= 21 && x.transmitterBatteryLevel <= 100).length;
				var batteryUnknown = transmittersX.filter((x) => x.transmitterBatteryLevel === -1).length;

				if (batteryVeryLow > 0) {
					transmitterArray.push({
						name: local.TF_Very_Low,
						y: batteryVeryLow,
						color: "#e63757",
						extra: transmittersX
							.filter((x) => x.transmitterBatteryLevel >= 0 && x.transmitterBatteryLevel <= 10)
							.map((x) => x.transmitterSerialNumber)
							.join(", "),
					});
				}
				if (batteryLow > 0) {
					transmitterArray.push({
						name: local.TS_Low,
						y: batteryLow,
						color: "#fcdd32",
						extra: transmittersX
							.filter((x) => x.transmitterBatteryLevel >= 11 && x.transmitterBatteryLevel <= 20)
							.map((x) => x.transmitterSerialNumber)
							.join(", "),
					});
				}
				if (batteryNormal > 0) {
					transmitterArray.push({ name: local.TS_Normal, y: batteryNormal, color: "#18aa6a" });
				}
				if (batteryUnknown > 0) {
					transmitterArray.push({
						name: local.TS_Unknown,
						y: batteryUnknown,
						color: "#888",
						extra: transmittersX
							.filter((x) => x.transmitterBatteryLevel === -1)
							.map((x) => x.transmitterSerialNumber)
							.join(", "),
					});
				}

				const transmitters = uniqueTransmitterSerial(latestReadings.filter((x) => x.transmitterSerialNumber), (item) => item.transmitterSerialNumber);
				for (var i = 0; i < transmitters.length; i++) {
					if (transmitters[i].transmitterBatteryLevel > 100 || transmitters[i].transmitterBatteryLevel === null) {
						transmitters[i].transmitterBatteryLevel = -1;
					}
					transmitters[i].transmitterBatteryLevelExport = transmitters[i].transmitterBatteryLevel === -1 ? "-" : transmitters[i].transmitterBatteryLevel;
					lowBatteryArray.push(transmitters[i]);
				}
			}

			if (isSubscribed) {
				setLowBatteries(lowBatteryArray);
				setData(transmitterArray);
				setLoading(false);
			}
		};
		LoadData();
		return () => (isSubscribed = false);
	}, [latestReadings]);

	const options = {
		animationEnabled: false,
		subtitles: [
			{
				text: local.TF_Battery_Levels,
				verticalAlign: "center",
				fontSize: 16,
				dockInsidePlotArea: true,
				fontFamily: ["Poppins", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"],
			},
		],
		legend: {
			fontSize: 14,
		},
		toolTip: {
			contentFormatter: function(e) {
				if ((e?.entries?.length || 0) > 0) {
					return `<div><div style='color: ${e.entries[0].dataPoint.color}'>${e.entries[0].dataPoint.name}: ${e.entries[0].dataPoint.y}</div>${e.entries[0].dataPoint.extra ? `<div>${e.entries[0].dataPoint.extra}</div>` : ""}</div>`;
				}
				return null;
			},
		},
		data: [
			{
				type: "doughnut",
				startAngle: 120,
				showInLegend: true,
				innerRadius: "85%",
				dataPoints: data,
			},
		],
	};

	const showBatteryLevels = () => {
		logEvent("View Battery Levels Table", "Menu Clicked on Dashboard Widget");
		if (viewBatteries) {
			viewBatteries(lowBatteries);
		}
	};

	return (
		<Col className="col-12 col-sm-6 col-lg-3 mb-2 d-flex align-items-stretch">
			<Card className="rounded-soft dash-border-grey w-100 p-1">
				{loading ? (
					<div className="ml-2">
						<Loader />
					</div>
				) : (
					<>
						{lowBatteries.length > 0 && (
							<div className="widget-menu" style={{ position: "absolute", right: 10, top: 10 }}>
								<Dropdown isOpen={open} toggle={() => setOpen(!open)}>
									<DropdownToggle tag="div" color="secondary" transform="shrink-3" size="sm" style={{ color: "#46505e" }}>
										<FontAwesomeIcon icon="ellipsis-v" fixedWidth />
									</DropdownToggle>
									<DropdownMenu right className="border">
										<DropdownItem onClick={() => showBatteryLevels()}>{local.TF_View_in_table}</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>
						)}
						<CanvasJSChart options={options} containerProps={{ height: "90%", minHeight: "250px" }} />
					</>
				)}
			</Card>
		</Col>
	);
};

export default withRedirect(BatteryLevelDoughnut);
