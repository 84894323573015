import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import moment from "moment";
import "moment/min/locales";
import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, CustomInput, Row } from "reactstrap";
import { siteDetailsSetForSite, siteDistributorDetailsSet, siteGetForSite, siteGraphicForSite, siteGraphicUploadForSite } from "../../api/site";
import defaultImage from "../../assets/img/defaults/default-user-banner.jpg";
import { defaultDistributorDetails } from "../../config";
import local from "../../localization/strings";

const SiteConfigure = ({ site, save, cancel }) => {
	const [loading, setLoading] = useState(true);
	const [siteImageOpen, setSiteImageOpen] = useState(false);
	const [image, setImage] = useState([""]);
	const [siteImage, setSiteImage] = useState(null);
	const [imageBrowsed, setImageBrowsed] = useState(false);
	const [productTypeSelected, setProductTypeSelected] = useState();

	const [distributorData, setDistributorData] = useState(null);

	useEffect(() => {
		const LoadData = async () => {
			setLoading(true);
			site.dateCreated = moment(site.dateCreated).format("YYYY-MM-DD");

			setProductTypeSelected(site.productType);

			// Get distributor details
			var result = await siteGetForSite(site.id);
			setDistributorData(result);

			const base64 = await siteGraphicForSite(site.id);
			if (base64) {
				setSiteImage(`data:image/png;base64,${base64}`);
			} else {
				setSiteImage(defaultImage);
			}
			if (!site.maxReadingsAgeMonths) {
				site.maxReadingsAgeMonths = "";
			}
			if (!site.siteType) {
				site.siteType = "";
			}
			setLoading(false);
		};

		setDistributorData(undefined);
		setImage(undefined);
		setSiteImage(undefined);
		LoadData();
	}, [site]);

	const handleNewImage = (e) => {
		setImage(e.target.files[0]);
		setSiteImage(URL.createObjectURL(e.target.files[0]));
		setImageBrowsed(true);
	};

	const distributorName = () => (distributorData ? distributorData.distributorName || defaultDistributorDetails.name : "");
	const distributorPhone = () => (distributorData ? distributorData.distributorPhone || defaultDistributorDetails.tel : "");
	const distributorEmail = () => (distributorData ? distributorData.distributorEmail || defaultDistributorDetails.email : "");

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	async function saveSite(_e, values) {
		await siteDetailsSetForSite(site.id, values.displayName, values.description, values.siteType);

		if (imageBrowsed) {
			const base64WithHeader = await toBase64(image);
			const base64Loc = base64WithHeader.indexOf("base64,");

			await siteGraphicUploadForSite(site.id, base64WithHeader.substring(base64Loc + 7));

			setImageBrowsed(false);
		}

		if (values.productType !== "Lite") {
			values.userLimit = null;
			values.channelLimit = null;
		}

		await save(_e, site.id, values.productType, values.userLimit, values.channelLimit, values.alarmNotificationsEnabled, values.alarmRecordingEnabled, values.smsAlarmNotificationsEnabled, values.maxReadingsAgeMonths, values.dateCreated);

		toast.success(local.TF_Site_config_changed);
	}

	async function saveDistributorDetails(_e, values) {
		await siteDistributorDetailsSet(site.id, values.distributorName, values.distributorPhone, values.distributorEmail);

		toast.success(local.TS_Update_Successful);
	}

	return (
		<Card className="p-3">
			{!loading && (
				<Row>
					<Col className="col-12 col-lg-6">
						<Card>
							<CardHeader>
								<h5>{local.TF_Configure_Site}</h5>
							</CardHeader>
							<CardBody className="bg-light border-top p-3">
								<AvForm model={site} onValidSubmit={async (e, values) => await saveSite(e, values)}>
									<AvField name="displayName" label={local.TF_Site_Name} validate={{ required: { value: true, errorMessage: local.TS_Required } }} maxLength={30} />
									<AvField name="description" label={local.TF_Description} validate={{ required: { value: true, errorMessage: local.TS_Required } }} maxLength={100} />

									<Row>
										<Col>
											<label>{local.TF_Site_Image}</label>
										</Col>
									</Row>
									{siteImage && (
										<>
											<Row>
												<Col>
													<Button onClick={() => setSiteImageOpen(true)} className="site-image-link">
														<img src={siteImage} className="rounded-soft" alt="Site" />
													</Button>
													{siteImageOpen && <Lightbox mainSrc={siteImage} onCloseRequest={() => setSiteImageOpen(false)} />}
												</Col>
											</Row>
											<Row>
												<Col>
													<small className="ml-3">{local.TF_Click_image_to_change}</small>
												</Col>
											</Row>
										</>
									)}
									<div className="site-image-link">
										<CustomInput type="file" id="newImage" name="newImage" label={local.TF_Change_Image} onChange={(e) => handleNewImage(e)} />
									</div>

									<AvField type="select" name="siteType" label={local.TS_Type}>
										<option value="" />
										<option value="Customer">{local.TF_Customer}</option>
										<option value="Archived">{local.TF_Archived}</option>
										<option value="Eltek">{local.TF_Eltek}</option>
										<option value="Hidden">{local.TF_Hidden}</option>
									</AvField>
									<AvGroup className={` ${productTypeSelected === "Lite" ? "p-3 border rounded" : ""} `}>
										<AvField label={local.TF_ProductType} type="select" name="productType" onChange={(e) => setProductTypeSelected(e.target.value)}>
											<option value="Lite">{local.TS_Lite}</option>
											<option value="Standard">{local.TS_Standard}</option>
										</AvField>
										{productTypeSelected === "Lite" && (
											<div className="pl-3 mb-2">
												<AvField type="number" name="userLimit" label={`${local.TF_UserLimit} (${site.userCount} ${local.TF_Assigned})`} min="0" validate={{ required: { value: true, errorMessage: local.TS_Required }, min: { value: site.userCount, errorMessage: `${local.TF_ValidateGreaterEqual} ${site.userCount}` }, step: { value: 1, errorMessage: local.TF_ValidatePositiveInteger } }} />
												<AvField type="number" name="channelLimit" label={`${local.TF_ChannelLimit} (${site.channelsWithTransmitterCount} ${local.TF_Assigned})`} min="0" validate={{ required: { value: true, errorMessage: local.TS_Required }, min: { value: site.channelsWithTransmitterCount, errorMessage: `${local.TF_ValidateGreaterEqual} ${site.channelsWithTransmitterCount}` }, step: { value: 1, errorMessage: local.TF_ValidatePositiveInteger } }} />
											</div>
										)}
									</AvGroup>
									<AvField type="number" name="maxReadingsAgeMonths" label={local.TF_ChangeMaxAge} min="0" max="60" />
									<AvField tag={CustomInput} type="checkbox" name="alarmNotificationsEnabled" label={local.TF_Alarm_Notifications_in_DC} />
									<AvField tag={CustomInput} type="checkbox" name="alarmRecordingEnabled" label={local.TF_Alarm_Recording_in_DC} />
									<AvField tag={CustomInput} type="checkbox" name="smsAlarmNotificationsEnabled" label={local.TF_SMS_Alarms} />
									<AvField name="dateCreated" label={local.TF_Created} type="date" />
									<Row>
										<Col>
											<Button color="primary" className="mt-3">
												{local.TS_Save_Changes}
											</Button>
											<Button color="secondary" className="mt-3 ml-2" onClick={cancel}>
												{local.TS_Cancel}
											</Button>
										</Col>
									</Row>
								</AvForm>
							</CardBody>
						</Card>
					</Col>
					<Col className="col-12 col-lg-6 mt-3 mt-lg-0">
						<Card>
							<CardHeader>
								<h5>{local.TS_Distributor_details}</h5>
							</CardHeader>
							<CardBody className="bg-light border-top p-3">
								<small>{local.TF_Contact_details_for_customer}</small>
								<AvForm onValidSubmit={async (e, values) => await saveDistributorDetails(e, values)}>
									<AvField name="distributorName" label={local.TS_Name} value={distributorName()} />
									<AvField name="distributorPhone" label={local.TS_Phone_Number} value={distributorPhone()} />
									<AvField name="distributorEmail" label={local.TS_Email} value={distributorEmail()} />
									<Row>
										<Col>
											<Button color="primary">{local.TS_Save_Changes}</Button>
											<Button color="secondary" className="ml-2" onClick={cancel}>
												{local.TS_Cancel}
											</Button>
										</Col>
									</Row>
								</AvForm>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}
		</Card>
	);
};

export default SiteConfigure;
