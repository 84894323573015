import React, { useCallback, useEffect, useState } from "react";
import ReactDataGrid from "react-data-grid";
import { Progress } from "reactstrap";
import { alarmHistory } from "../../../api/reports";
import { features, isFeatureEnabled } from "../../../config";
import { chunk, measureText } from "../../../helpers/utils";
import useWindowSize from "../../../hooks/useWindowSize";
import Loader from "../../common/Loader";

const AlarmReportDisplay = ({ filterData, reportComplete }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [columns, setColumns] = useState([]);
	const [rowCount, setRowCount] = useState(0);
	const size = useWindowSize();
	const [minHeight, setMinHeight] = useState(500);
	const [progress, setProgress] = useState(0);
	const [progressLength, setProgressLength] = useState(0);

	useEffect(() => {
		size.then((r) => setMinHeight(r.height - 225));

		var dashboardContentDiv = document.getElementById("dashboard-container-div");

		if (dashboardContentDiv) {
			var contentDiv = document.getElementById("alarm-report-div");

			if (contentDiv) {
				contentDiv.style.marginRight = `-${dashboardContentDiv.offsetLeft}px`;
			}
		}
	}, [size]);

	const updateProgress = useCallback(() => {
		setProgress((p) => p + 1);
	}, []);

	useEffect(() => {
		const LoadData = async () => {
			const { groupIds, period, startTime, endTime } = filterData;

			if (groupIds.length === 0) {
				setData(null);
				setRowCount(0);
				reportComplete(undefined);
			} else {
				let resultsData = null;

				const chunks = chunk(groupIds, 10);
				setProgress(0);
				setProgressLength(chunks.length);

				for (const i in chunks) {
					var result = await alarmHistory(chunks[i].join(","), period, 1, startTime, endTime, isFeatureEnabled(features.Seconds));
					updateProgress();

					//First set?
					if (!resultsData) {
						resultsData = result;
					} else {
						//Append new rows
						resultsData.rows.push(...result.rows);
						resultsData.rowsCount = resultsData.rowsCount + result.rowsCount;

						//Append new columns
						for (const j in result.columns) {
							let found = false;
							for (const k in resultsData.columns) {
								if (result.columns[j].key === resultsData.columns[k].key) {
									found = true;
								}
							}
							if (!found) {
								resultsData.columns.push(result.columns[j]);
							}
						}
					}

					//Apply cell formatters
					resultsData.columns.forEach((column) => {
						switch (column.extra) {
							case "Colour":
								column.formatter = ValueFormatter;
								break;
							case "Battery":
								column.formatter = BatteryFormatter;
								break;
							default:
								column.formatter = NormalFormatter;
								break;
						}
					});

					//Reformat for no battery levels or signal
					resultsData.rows.forEach((row) => {
						if (row.BatteryLevel === 255) {
							row.BatteryLevel = "-";
						}
					});

					resultsData.rows.forEach((row) => {
						if (row.SignalStrength === 255) {
							row.SignalStrength = "-";
						}
					});

					//Sort data (DateTime, newest first)
					resultsData.rows.sort((a, b) => {
						try {
							return Date.parse(a.DateTime) > Date.parse(b.DateTime) ? -1 : 1;
						} catch {
							return 0;
						}
					});

					//Resize columns 1,2,3 (not 0)
					for (var col = 1; col < 4; ++col) {
						let width = (measureText(resultsData.columns[col].name, 13, null)?.width || 0) + 16;
						try {
							for (let i = 0; i < resultsData.rows.length; ++i) {
								const thisWidth = measureText(resultsData.rows[i][resultsData.columns[col].key], 12, null)?.width + 16;
								if (thisWidth > width) {
									width = thisWidth;
								}
							}
						} catch {}
						resultsData.columns[col].width = width;
					}

					//Show it
					setColumns(resultsData.columns.concat());
					setData(resultsData);
					setRowCount(resultsData.rowsCount); //This causes the redraw, if not done then only first set of data is shown
					setLoading(false);
				}
				reportComplete(resultsData);
			}
		};

		LoadData();
	}, [filterData, reportComplete, updateProgress]);

	const NormalFormatter = ({ value }) => (
		<div title={value} className="rdg_innerCell">
			{value}
		</div>
	);

	const ValueFormatter = ({ value }) => {
		return (
			value && (
				<div title={value} className="rdg_innerCell bg-secondary text-white">
					{value}
				</div>
			)
		);
	};

	const BatteryFormatter = ({ value }) => {
		if (value === "-") {
			return (
				<div title={value} className="rdg_innerCell bg-secondary text-white">
					{value}
				</div>
			);
		}
		if (value > -1 && value <= 10) {
			return (
				<div title={value} className="rdg_innerCell battery-very-low">
					{value}
				</div>
			);
		}
		if (value > 10 && value <= 20) {
			return (
				<div title={value} className="rdg_innerCell battery-low">
					{value}
				</div>
			);
		}
		return (
			value && (
				<div title={value} className="rdg_innerCell">
					{value}
				</div>
			)
		);
	};

	return (
		<>
			{loading ? <Loader /> : <div className="rdg-no-gutters rounded-grid">{data && rowCount > 0 && <ReactDataGrid columns={columns} minColumnWidth={40} resizable={true} rowGetter={(i) => data.rows[i]} rowsCount={rowCount} minHeight={minHeight} enableRowSelect={null} />}</div>}
			{progressLength > 0 && progress !== progressLength && <Progress animated value={progress} max={progressLength} />}
		</>
	);
};

export default AlarmReportDisplay;
