import { faExclamationCircle, faSignal1, faSignal2, faSignal3, faSignal4 } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactEcharts from "echarts-for-react";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { formatDateTime } from "../../helpers/dateTimeHelper";
import local from "../../localization/strings";
import Pin from "../common/Pin";
import BatteryIcon from "./BatteryIcon";
import { WidgetTitleLevel1, WidgetTitleLevel2 } from "../common/WidgetTitles";

const GaugeMeter = ({ reading, showTransmitterDetails }) => {
	const [itemHeight, setItemHeight] = useState("230px");
	const [format, setFormat] = useState("{value}");
	const [bgColour, setBgColour] = useState("");

	useEffect(() => {
		function gaugeHeight() {
			var w = window.innerWidth;
			if (w <= 576) {
				setItemHeight("230px");
			} else if (w > 576 && w <= 768) {
				setItemHeight("200px");
			} else if (w > 768 && w <= 992) {
				setItemHeight("230px");
			} else if (w > 992 && w <= 1200) {
				setItemHeight("170px");
			} else if (w > 1200 && w <= 1540) {
				setItemHeight("160px");
			} else {
				setItemHeight("220px");
			}
			return itemHeight;
		}

		const readingFormat = () => {
			if (typeof reading.logging === "boolean" && !reading.logging) {
				setFormat(local.TF_OFF);
			} else if (reading.realValue === null) {
				setFormat(local.TS_No_Data);
			} else {
				setFormat(reading.realValue.toFixed(reading.decimalPlaces) + reading.units);
			}
		};
		gaugeHeight();
		readingFormat();

		if (typeof reading.logging === "boolean" && !reading.logging) {
			setBgColour("");
		} else {
			switch (reading?.alarmType || "") {
				case "MainLo":
				case "MainHi":
					setBgColour(reading?.clearedBy ? "widgetInAlarmCleared" : "widgetInAlarm");
					break;
				default:
					setBgColour("");
					break;
			}
		}

		window.addEventListener("resize", gaugeHeight);
	}, [itemHeight, reading]);

	function getOptions() {
		const options = {
			animation: false,
			tooltip: {
				show: false,
			},
			toolbox: {
				show: false,
			},
			series: [
				{
					title: { offsetCenter: [0, "-120%"] },
					name: reading.parameterName,
					type: "gauge",
					detail: {
						formatter: format,
						offsetCenter: [0, "80%"],
						fontSize: 16,
					},
					data: [{ value: format === local.TF_OFF ? null : reading.realValue, name: reading.parameterName }],
					min: reading.rangeMin,
					max: reading.rangeMax,
					splitNumber: 4,
					axisLine: {
						lineStyle: {
							color: [[0.2, "#e63757"], [0.4, "#ffae19"], [0.6, "#18aa6a"], [0.8, "#ffae19"], [1, "#e63757"]],
							width: 10,
							shadowColor: "#51525c",
							shadowBlur: 5,
						},
					},
					splitLine: {
						show: false,
						length: 10,
					},
					axisLabel: {},
				},
			],
		};

		options.series[0].axisLine.lineStyle.color = [];

		if (reading.redLo) {
			options.series[0].axisLine.lineStyle.color.push([reading.redLo, "#e63757"]);
		}

		if (reading.amberLo) {
			options.series[0].axisLine.lineStyle.color.push([reading.amberLo, "#ffae19"]);
		}

		if (reading.green === 0) {
			reading.green = 1;
		}
		options.series[0].axisLine.lineStyle.color.push([reading.green, "#18aa6a"]);

		if (reading.amberHi) {
			options.series[0].axisLine.lineStyle.color.push([reading.amberHi, "#ffae19"]);
		}

		if (reading.redHi) {
			options.series[0].axisLine.lineStyle.color.push([reading.redHi, "#e63757"]);
		}

		return options;
	}

	const Lqi = () => {
		if (!reading.transmitterLqi) {
			return null;
		}

		let lqiText = local.TF_LQI_Limit;
		let fa = faSignal1;
		switch (reading.transmitterLqi) {
			case "Excellent":
				lqiText = local.TF_LQI_Excellent;
				fa = faSignal4;
				break;
			case "Good":
				lqiText = local.TF_LQI_Good;
				fa = faSignal3;
				break;
			case "Average":
				lqiText = local.TF_LQI_Average;
				fa = faSignal2;
				break;
			case "Limit":
			default:
				break;
		}

		return (
			<div style={{ position: "absolute", left: 15, top: 15, zIndex: 1000 }}>
				<FontAwesomeIcon icon={fa} title={lqiText} />
			</div>
		);
	};

	return (
		<Card className={`h-100 m-2 ${bgColour}`} style={{ minWidth: "200px" }}>
			<CardBody className="my-0 pb-1 pt-2">
				<Lqi />
				<div style={{ position: "absolute", right: 15, top: 15, zIndex: 800 }}>
					<Pin color="secondary" types={[{ name: `${local.TF_Pin_Gauge}`, type: "DashMeter", data: reading.locationId }, { name: `${local.TF_Pin_Gauge_Blue}`, type: "DashMeterBlue", data: reading.locationId }]} size="sm" className="menu-pin" menuClass="menu-border-blue" tag="div" />
				</div>
				<ReactEcharts style={{ height: "200px", minWidth: "180px" }} option={getOptions()} lazyUpdate={true} opts={{ renderer: "svg" }} className="ml-n1" />
				<div className="mt-n3">
					<WidgetTitleLevel1 reading={reading} />
				</div>
				<div className="small">
					<WidgetTitleLevel2 reading={reading} />
					{reading.outOfDateReading && <FontAwesomeIcon className="danger-icon" size="lg" icon={faExclamationCircle} title={`OUT OF DATE: ${formatDateTime(reading.dateTime)}`} />}
				</div>
				{showTransmitterDetails && (
					<Fragment>
						<hr className="mx-n3" />
						<div className="text-center small">
							{local.TS_Transmitter}: {reading.transmitterSerialNumber}
						</div>
						<div className="text-center small">
							<BatteryIcon reading={reading.transmitterBatteryLevel} />
						</div>
					</Fragment>
				)}
			</CardBody>
		</Card>
	);
};

GaugeMeter.propTypes = {
	reading: PropTypes.object.isRequired,
};

export default GaugeMeter;
