import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactDataGrid from "react-data-grid";
import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../common/Loader";

function MetersTable({ readings, showTransmitterDetails }) {
	const [minHeight, setMinHeight] = useState(500);
	const [showLoading, setShowLoading] = useState(true);
	const [data, setData] = useState(null);
	const size = useWindowSize();

	useEffect(() => {
		size.then((r) => setMinHeight(r.height - 300));
	}, [size]);

	useEffect(() => {
		setShowLoading(true);

		if (readings && readings.columns && readings.columns[0]) {
			readings.columns[0].formatter = NormalFormatter;
			readings.columns[1].formatter = NormalFormatter;
			readings.columns[2].formatter = NormalFormatter;

			if (showTransmitterDetails) {
				readings.columns[3].formatter = NormalFormatter;
				readings.columns[4].formatter = BatteryFormatter;
				readings.columns.slice(5).forEach(function(column) {
					column.formatter = ValueFormatter;
					column.headerRenderer = HeaderValueFormatter;
				});
			} else {
				readings.columns.slice(3).forEach(function(column) {
					column.formatter = ValueFormatter;
					column.headerRenderer = HeaderValueFormatter;
				});
			}

			setData(readings);
			setShowLoading(false);
		} else {
			setData(null);
		}
	}, [readings, showTransmitterDetails]);

	const HeaderValueFormatter = ({ column }) => <div className="text-right">{column.name}</div>;
	const BatteryFormatter = ({ value }) => <div className="rdg_innerCell text-right">{value ? `${value}%` : ""}</div>;
	const NormalFormatter = ({ value }) => <div className="rdg_innerCell">{value}</div>;
	const ValueFormatter = ({ value }) => {
		const parts = value.split(":");

		if ((parts[0] === "None" && parts[1] === "") || parts[0] === "") {
			return <div className={`rdg_innerCell text-right`}>{parts[2] === "True" && <FontAwesomeIcon className="danger-icon fs-1" size="lg" icon={faExclamationCircle} title="OUT OF DATE" />} -</div>;
		} else {
			return (
				<div className={`rdg_innerCell text-right alarm-${parts[0] || "none"}`}>
					{parts[2] === "True" && <FontAwesomeIcon className="danger-icon fs-1" size="lg" icon={faExclamationCircle} title="OUT OF DATE" />}
					{parts[1]}
				</div>
			);
		}
	};

	return (
		<>
			{showLoading ? (
				<Loader />
			) : (
				<>
					<div className="rdg-no-gutters">
						<ReactDataGrid columns={data.columns} sortable={true} resizable={true} rowGetter={(i) => data.rows[i]} rowsCount={data.rowsCount} minHeight={minHeight} enableRowSelect={null} />
					</div>
				</>
			)}
		</>
	);
}

export default MetersTable;
