import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import moment from "moment";
import "moment/min/locales";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledDropdown } from "reactstrap";
import { siteAlarmNotificationsEnabledSet, siteAlarmRecordingEnabledSet, siteBillingSet, siteDateCreatedSet, siteMaxAgeSet, siteProductTypeSet, siteSmsAlarmNotificationsEnabledSet, siteStats } from "../../api/site";
import { environments, features, getEnvironment, isFeatureEnabled } from "../../config";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import AddButton from "../common/AddButton";
import CancelButton from "../common/CancelButton";
import FontAwesomeButton from "../common/FontAwesomeButton";
import Loader from "../common/Loader";
import DisplaySiteId from "./DisplaySiteId";
import SiteAccess from "./SiteAccess";
import SiteConfigure from "./SiteConfigure";
import SiteUserEdit from "./SiteUserEdit";
import SiteUsers from "./SiteUsers";
import SmsUsage from "./SmsUsage";
// import { demoSiteDelete } from "../../api/demoSite";
import { faBell, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { alarmTeamEmergency } from "../../api/alarmTeams";
import { SiteRequiresMFA } from "../../api/auth";
import ConfigureMfa from "../mfa/ConfigureMfa";
import MFA from "../mfa/MFA";
import SiteBilling from "./SiteBilling";
import SiteSms from "./SiteSms";

const SiteList = ({ toggleAssignDistributor, add, addDemo }) => {
	const [accessSiteId, setAccessSiteId] = useState(null);
	const [displaySiteId, setDisplaySiteId] = useState(null);
	const [displayName, setDisplayName] = useState(null);
	const [data, setData] = useState(undefined);
	const [showLoading, setShowLoading] = useState(true);
	const [showFirstReadingDate, setShowFirstReadingDate] = useState(false);
	const [focusSite, setFocusSite] = useState(undefined);
	const [focusUserId, setFocusUserId] = useState(undefined);
	const [mode, setMode] = useState("List");
	const [demo, setDemo] = useState(false);
	const [mfaSiteId, setMFASiteId] = useState(undefined);

	useEffect(() => {
		const LoadData = async () => {
			var allSites = await siteStats(false);
			setData(allSites);
		};

		LoadData();
		if (getEnvironment() === environments.Demo) {
			setDemo(true);
		}
		setShowLoading(false);
	}, []);

	const toggleShowFirstReadingDate = async (newValue) => {
		setShowFirstReadingDate(newValue);
		setData(await siteStats(newValue));
	};

	const triggerConfig = (site) => {
		setFocusSite(site);
		setMode("Configure");
	};

	const triggerBilling = (site) => {
		setFocusSite(site);
		setMode("Billing");
	};

	const triggerSms = (site) => {
		setFocusSite(site);
		setMode("Sms");
	};

	const triggerConfigMfa = (site) => {
		setFocusSite(site);
		setMode("ConfigureMFA");
	};

	const triggerSiteUsers = (site) => {
		setFocusSite(site);
		setMode("Users");
	};

	const cancel = async () => {
		setMode("List");
		setFocusUserId(undefined);
		setFocusSite(undefined);
		setData(await siteStats(showFirstReadingDate));
	};

	const saveConf = async (e, siteId, siteProduct, userLimit, channelLimit, siteAlarmsEnabled, siteAlarmRecordingEnabled, siteSmsAlarmNotificationsEnabled, siteMaxAge, dateCreated) => {
		e.preventDefault();

		await siteProductTypeSet(siteId, siteProduct, userLimit, channelLimit);
		await siteMaxAgeSet(siteId, siteMaxAge);
		await siteAlarmNotificationsEnabledSet(siteId, siteAlarmsEnabled);
		await siteAlarmRecordingEnabledSet(siteId, siteAlarmRecordingEnabled);
		await siteSmsAlarmNotificationsEnabledSet(siteId, siteSmsAlarmNotificationsEnabled);
		await siteDateCreatedSet(siteId, dateCreated);

		setData(await siteStats(showFirstReadingDate));
	};

	const saveBilling = async (e, siteId, amazonEC2, simCard, salesOrderNumber, contractStartDate) => {
		e.preventDefault();

		await siteBillingSet(siteId, amazonEC2, simCard, salesOrderNumber, contractStartDate);

		setData(await siteStats(showFirstReadingDate));

		cancel();
		toast.success(local.TF_Site_Billing_changed);
	};

	const editUser = (id) => {
		setFocusUserId(id);
		setMode("UserEdit");
	};

	const saveUser = () => {
		setMode("Users");
		setFocusUserId(undefined);
	};

	const siteDetails = (name, id) => {
		setDisplayName(name);
		setDisplaySiteId(id);
	};

	const activateEmergencyAlarms = async (id) => {
		await alarmTeamEmergency(id, true);
		var allSites = await siteStats(false);
		setData(allSites);
	};
	const deactivateEmergencyAlarms = async (id) => {
		await alarmTeamEmergency(id, false);
		var allSites = await siteStats(false);
		setData(allSites);
	};

	const columns = [
		{
			title: local.TS_Actions,
			render: (rowData) => (
				<>
					{SiteRequiresMFA(rowData.id) ? (
						<>
							<FontAwesomeIcon className="danger-icon fs-1 mr-2 invisible" icon={faExclamationCircle} />
							<FontAwesomeIcon className="danger-icon fs-1 mr-2 invisible" icon={faExclamationCircle} />
							<FontAwesomeIcon icon="lock" className="cursor-pointer" onClick={() => setMFASiteId(rowData.id)} />
						</>
					) : (
						<div className="d-flex flex-nowrap justify-content-center">
							{rowData.darcaHubOffline ? <FontAwesomeIcon className="danger-icon fs-1 mr-2" icon={faExclamationCircle} title={local.TF_Darca_hub_offline} /> : <FontAwesomeIcon className="danger-icon fs-1 mr-2 invisible" icon={faExclamationCircle} />}
							{rowData.emergencyAlarmCountOn > 0 ? <FontAwesomeIcon className="danger-icon fs-1 mr-2" icon={faBell} title={local.TS_Emergency_Alarms_Enabled} /> : <FontAwesomeIcon className="danger-icon fs-1 mr-2 invisible" icon={faExclamationCircle} />}
							<UncontrolledDropdown>
								<DropdownToggle tag="div" className="pin-button">
									<FontAwesomeIcon icon="ellipsis-v" fixedWidth />
								</DropdownToggle>
								<DropdownMenu className="border" positionFixed>
									<DropdownItem onClick={() => setAccessSiteId(rowData.id)}>{local.TF_Access_Site}</DropdownItem>
									<DropdownItem divider />
									<DropdownItem onClick={() => triggerConfig(rowData)}>{local.TF_Configure_Site}</DropdownItem>
									<DropdownItem onClick={() => triggerConfigMfa(rowData)}>{local.TF_Configure_MFA}</DropdownItem>
									<DropdownItem onClick={() => siteDetails(rowData.displayName, rowData.id)}>{local.TF_Display_Site_ID}</DropdownItem>
									<DropdownItem onClick={() => triggerBilling(rowData)}>{local.TF_Billing_Information}</DropdownItem>
									<DropdownItem onClick={() => triggerSms(rowData)}>{local.TF_Sms_Information}</DropdownItem>
									<DropdownItem divider />
									<DropdownItem onClick={() => triggerSiteUsers(rowData)}>{local.TS_Users}</DropdownItem>
									{(rowData.emergencyAlarmCountOff > 0 || rowData.emergencyAlarmCountOn > 0) && <DropdownItem divider />}
									{rowData.emergencyAlarmCountOff > 0 && (
										<DropdownItem onClick={() => activateEmergencyAlarms(rowData.id)}>
											{local.TS_Emergency_Alarms_Enable}
											<div className="fs--2">({local.TS_Emergency_Alarms_Warn})</div>
										</DropdownItem>
									)}
									{rowData.emergencyAlarmCountOn > 0 && <DropdownItem onClick={() => deactivateEmergencyAlarms(rowData.id)}>{local.TS_Emergency_Alarms_Disable}</DropdownItem>}
									{/* <DropdownItem onClick={() => toggleAssignDistributor()}>{local.TF_Assign_Distributor}</DropdownItem> */}
									{/* {demo && (
								<>
									<DropdownItem divider />
									<DropdownItem onClick={() => demoSiteDelete(rowData.id)}>
										<span className="text-danger">Delete Site</span>
									</DropdownItem>
								</>
							)} */}
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					)}
				</>
			),
		},
		{ title: local.TF_Site_Name, field: "displayName", defaultSort: "asc" },
		{ title: local.TF_Description, field: "description" },
		{ title: local.TS_Type, field: "siteType" },
		{ title: local.TF_Distributor, field: "distributorName" },
		{
			title: local.TF_ContractStart,
			field: "contractStartDate",
			render: (rowData) => <div>{rowData.contractStartDate ? moment(rowData.contractStartDate).format("DD MMM YYYY") : ""}</div>,
		},
		{
			title: local.TF_Sales_Order_Number,
			field: "salesOrderNumberDisplay",
		},
		{
			title: local.TF_ProductType,
			field: "productType",
			render: (rowData) => (
				<>
					{rowData.productType}
					{rowData.maxReadingsAgeMonths && <div>({rowData.maxReadingsAgeMonths} months)</div>}
				</>
			),
		},
		{ title: local.TF_NumberOfUsers, field: "userCount" },
		{ title: local.TF_NumberOfTransmitters, field: "transmitterCount" },
		{ title: local.TF_NumberOfTransmitterChannels, field: "transmitterChannelCount", render: (rowData) => <div title={local.TF_SiteList_TXChannels_Tooltip}> {rowData.transmitterChannelDisplay}</div> },
		{ title: local.TF_LogIntervalPoints, field: "logInternalPoints" },
		{
			title: local.TF_Alarms,
			field: "alarmNotificationsEnabled",
			render: (rowData) => (
				<>
					{rowData.alarmRecordingEnabled ? <FontAwesomeIcon size="lg" icon="microphone" className="text-success" fixedWidth /> : <FontAwesomeIcon size="lg" icon="microphone-slash" fixedWidth className="text-secondary" />}
					{rowData.alarmNotificationsEnabled ? <FontAwesomeIcon size="lg" icon="lightbulb" className="text-success" fixedWidth /> : <FontAwesomeIcon size="lg" icon="lightbulb-slash" fixedWidth className="text-secondary" />}
					{rowData.smsAlarmNotificationsEnabled ? <FontAwesomeIcon size="lg" icon="comment" className="text-success" fixedWidth /> : <FontAwesomeIcon size="lg" icon="comment-slash" fixedWidth className="text-secondary" />}
				</>
			),
		},
		{ title: local.TF_SMS_Usage, field: "smsUsage", render: (rowData) => <SmsUsage added={rowData.activeSmsSegmentsAdded} remaining={rowData.activeSmsSegmentsRemaining} siteId={rowData.id} /> },
		{
			title: local.TF_Created,
			field: "dateCreated",
			export: false,
			render: (rowData) => <div>{moment(rowData.dateCreated).format("DD MMM YYYY")}</div>,
		},
		{
			title: local.TF_Created,
			field: "dateCreatedFormatted",
			export: true,
			cellStyle: { display: "none" },
			headerStyle: { display: "none" },
		},
		{
			title: local.TF_FirstReading,
			field: "firstReadingDateTime",
			export: false,
			render: (rowData) => <div>{rowData.firstReadingDateTime ? moment(rowData.firstReadingDateTime).format("DD MMM YYYY") : ""}</div>,
			cellStyle: { display: showFirstReadingDate ? "" : "none" },
			headerStyle: { display: showFirstReadingDate ? "" : "none" },
		},
		{
			title: local.TF_FirstReading,
			field: "firstReadingDateTimeFormatted",
			export: true,
			cellStyle: { display: "none" },
			headerStyle: { display: "none" },
		},
		{
			title: local.TF_Site_ID,
			field: "id",
			export: true,
			cellStyle: { display: "none" },
			headerStyle: { display: "none" },
		},
		{ title: "", field: "attributes" },
	];

	return (
		<>
			<Modal isOpen={!!mfaSiteId} toggle={() => setMFASiteId(undefined)} centered backdrop="static">
				<ModalBody>
					<MFA siteId={mfaSiteId} onCancel={() => setMFASiteId(undefined)} />
				</ModalBody>
			</Modal>
			<SiteAccess siteId={accessSiteId} cancelAccessSite={() => setAccessSiteId(undefined)} />
			<DisplaySiteId siteId={displaySiteId} siteName={displayName} cancelDisplaySiteId={() => setDisplaySiteId(false)} />

			{mode === "Users" ? (
				<>
					<span className="float-right pt-2 mt-n5">
						<CancelButton string={local.TS_Back} buttonClasses={"mt-n2 mb-2 mr-2"} clickFunction={() => cancel()} />
						<AddButton string={local.TF_Add_User} buttonClasses={"mt-n2 mb-2"} clickFunction={() => editUser(undefined)} />
					</span>

					<SiteUsers site={focusSite} editUser={editUser} />
				</>
			) : mode === "UserEdit" ? (
				<SiteUserEdit site={focusSite} userId={focusUserId} cancel={cancel} done={saveUser} />
			) : mode === "Configure" ? (
				<>
					<span className="float-right pt-2 mt-n5">
						<CancelButton string={local.TS_Back} buttonClasses={"mt-n2 mb-2 mr-2"} clickFunction={() => cancel()} />
					</span>
					<SiteConfigure site={focusSite} cancel={cancel} save={saveConf} />
				</>
			) : mode === "Billing" ? (
				<SiteBilling site={focusSite} cancel={cancel} save={saveBilling} />
			) : mode === "Sms" ? (
				<>
					<span className="float-right pt-2 mt-n5">
						<CancelButton string={local.TS_Back} buttonClasses={"mt-n2 mb-2 mr-2"} clickFunction={() => cancel()} />
					</span>
					<SiteSms site={focusSite} />
				</>
			) : mode === "ConfigureMFA" ? (
				<>
					<span className="float-right pt-2 mt-n5">
						<CancelButton string={local.TS_Back} buttonClasses={"mt-n2 mb-2 mr-2"} clickFunction={() => cancel()} />
					</span>

					<ConfigureMfa site={focusSite} cancel={cancel} />
				</>
			) : (
				<>
					<span className="float-right pt-2 mt-n5">
						{mode === "List" && <FontAwesomeButton color="secondary" icon="puzzle-piece" text={local.TF_Toggle_First_Reading} onClick={() => toggleShowFirstReadingDate(!showFirstReadingDate)} />}
						{isFeatureEnabled(features.SiteCreate) && <>{demo ? <AddButton string="Add Demo Site" clickFunction={addDemo} /> : <AddButton string={local.TF_Create_Site} clickFunction={add} />}</>}
					</span>

					<Card className="placeholder-card">
						{showLoading ? (
							<Loader />
						) : (
							data && (
								<div className="MuiTable text-center">
									<MaterialTable
										columns={columns}
										data={data}
										title=""
										icons={tableIcons}
										options={{
											padding: "dense",
											sorting: true,
											exportButton: true,
											exportAllData: true,
											exportFileName: "Site List",
											paging: true,
											pageSize: 20,
											pageSizeOptions: [20, 50, 100],
											emptyRowsWhenPaging: false,
											headerStyle: {
												backgroundColor: "#fff",
												color: "#000",
											},
											rowStyle: {
												textAlign: "center",
											},
										}}
									/>
								</div>
							)
						)}
					</Card>
				</>
			)}
		</>
	);
};

export default SiteList;
