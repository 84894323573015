import moment from "moment";
import "moment/min/locales";
import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import local from "../../localization/strings";

const SiteBilling = ({ site, save, cancel }) => {
	const [siteId, setSiteId] = useState(undefined);
	const [amazonEC2, setAmazonEC2] = useState(undefined);
	const [simCard, setSIMCard] = useState(undefined);
	const [salesOrderNumber, setSalesOrderNumber] = useState(undefined);
	const [contractStartDate, setContractStartDate] = useState(undefined);

	useEffect(() => {
		if (site) {
			setSiteId(site.id);
			setAmazonEC2(site.amazonEC2);
			setSIMCard(site.simCard);
			setSalesOrderNumber(site.salesOrderNumber || "");
			setContractStartDate(site.contractStartDate ? moment(site.contractStartDate).format("YYYY-MM-DD") : "");
		} else {
			setSiteId(undefined);
			setAmazonEC2(undefined);
			setSIMCard(undefined);
			setSalesOrderNumber("");
			setContractStartDate("");
		}
	}, [site]);

	return (
		<Card className="p-3">
			{siteId && (
				<Form onSubmit={async (e) => await save(e, siteId, amazonEC2, simCard, salesOrderNumber, contractStartDate)}>
					<FormGroup tag="fieldset">
						<Label for="alarmRadio">{local.TF_Amazon_EC2}:</Label>
						<FormGroup check>
							<Label check>
								<Input type="radio" value="true" name="alarmRadio" onChange={() => setAmazonEC2(true)} checked={amazonEC2 ? true : false} /> {local.TS_Yes}
							</Label>
						</FormGroup>
						<FormGroup check>
							<Label check>
								<Input type="radio" value="false" name="alarmRadio" onChange={() => setAmazonEC2(false)} checked={!amazonEC2 ? true : false} /> {local.TS_No}
							</Label>
						</FormGroup>
					</FormGroup>
					<FormGroup tag="fieldset">
						<Label for="alarmRecord">{local.TF_SIM_Card}:</Label>
						<FormGroup check>
							<Label check>
								<Input type="radio" value="true" name="alarmRecord" onChange={() => setSIMCard(true)} checked={simCard ? true : false} /> {local.TS_Yes}
							</Label>
						</FormGroup>
						<FormGroup check>
							<Label check>
								<Input type="radio" value="false" name="alarmRecord" onChange={() => setSIMCard(false)} checked={!simCard ? true : false} /> {local.TS_No}
							</Label>
						</FormGroup>
					</FormGroup>
					<FormGroup tag="fieldset">
						<Label for="salesOrderNumber">{local.TF_Sales_Order_Number}:</Label>
						<div className="mx-2 mb-2">
							<Input type="number" min="0" value={salesOrderNumber} name="salesOrderNumber" onChange={({ target }) => setSalesOrderNumber(target.value)} />
						</div>
					</FormGroup>
					<FormGroup tag="fieldset">
						<Label for="salesOrderNumber">{local.TF_ContractStart}:</Label>
						<div className="mx-2 mb-2">
							<Input type="date" min="0" value={contractStartDate} name="salesOrderNumber" onChange={({ target }) => setContractStartDate(target.value)} />
						</div>
					</FormGroup>
					<Row>
						<Col>
							<Button color="primary" className="mt-2 float-left">
								{local.TS_Save_Changes}
							</Button>
							<Button color="secondary" className="mt-2 ml-2 float-left" onClick={cancel}>
								{local.TS_Cancel}
							</Button>
						</Col>
					</Row>
				</Form>
			)}
		</Card>
	);
};

export default SiteBilling;
